<template>
  <Modal
    v-if="searchModal"
    @close="searchModal = !searchModal"
    :modalActive="searchModal"
    modalCssClass="modal-fullscreen"
  >
    <template v-slot:header>{{ t("general.search_fulltext") }}</template>
    <template v-slot:body>
      <div class="gws_control_bar d-lg-flex justify-content-between align-items-center gap-2 p-2 mb-2">
        <div class="d-sm-flex align-items-center gap-2">
          <!-- search input -->
          <div class="form-floating mb-2 mb-sm-0">
            <input
              type="text"
              class="form-control"
              id="searchFulltext"
              v-model="searchTerm"
              @keyup.enter="searchFulltext()"
            />
            <label for="search">{{ t("general.search") }}</label>
          </div>
          <!-- search type -->
          <div class="form-floating mb-2 mb-lg-0">
            <select class="form-select" id="search_type" aria-label="Select Search Type" v-model="selectedSearchType">
              <!-- @change="searchFulltext()" -->
              <option v-for="searchType in searchTypes" :key="searchType.type" :value="searchType.type">
                {{ searchType.name }}
              </option>
            </select>
            <label for="search_type">{{ t("general.searchtype") }}</label>
          </div>
          <!-- categories -->
          <div v-if="categories.length > 0" class="form-floating mb-2 mb-lg-0">
            <select
              class="form-select"
              id="serach_categories"
              aria-label="Select Categorie"
              v-model="selectedCategoryId"
            >
              <!-- @change="searchFulltext()" -->
              <option value=""></option>
              <option v-for="category in categories" :key="category.id" :value="category.id">
                {{ category.category }}
              </option>
            </select>
            <label for="serach_categories">{{ t("general.categories") }}</label>
          </div>
          <div class="form-floating mb-2 mb-sm-0">
            <button @click="searchFulltext()" class="btn btn-success gws_btn_icon_only" :title="t('general.search')">
              <svg width="20" height="20" class="bi" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#search"></use>
              </svg>
            </button>
          </div>
          <!-- number of search results -->
          <div class="border border-light rounded p-1 mt-2 mt-sm-0">
            <small>{{ addresses.length }} {{ t("general.hits") }}</small>
          </div>
          <!-- save as CSV -->
          <div v-if="searchHasResults" class="form-floating mb-2 mb-sm-0">
            <button
              @click="saveSearchResultsAsCSV()"
              class="btn btn-primary gws_btn_icon_only"
              :title="t('general.save_as_csv')"
            >
              <svg width="20" height="20" class="bi" fill="currentColor">
                <use xlink:href="/assets/img/bootstrap-icons.svg#file-earmark-spreadsheet"></use>
              </svg>
            </button>
          </div>
          <!-- loader -->
          <div v-if="searchLoader" class="spinner-border text-secondary global-loader" role="status"></div>
        </div>
      </div>
      <!-- SEARCH RESULTS -->
      <div class="gws_address_search">
        <div class="card-group gws_card-group" v-if="addresses.length > 0">
          <!-- <pre>{{ addresses }}</pre> -->
          <div class="card gws_search_results mb-sm-2 text-dark me-2" v-for="address in addresses" :key="address.id">
            <div class="card-header d-flex justify-content-end">
              <router-link
                :to="'/addresses/edit/' + address.id"
                class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
                :title="t('general.edit')"
                @click="searchModal = !searchModal"
              >
                <svg width="20" height="20" class="bi" fill="currentColor">
                  <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
                </svg>
                {{ t("general.edit") }}
              </router-link>
            </div>
            <div class="card-body">
              <div class="card-text">
                <dl>
                  <template v-if="address.name !== null">
                    <dt>{{ t("address.name") }}</dt>
                    <dd>{{ address.name }}</dd>
                  </template>
                  <template v-if="address.surname !== null || address.forename !== null">
                    <dt>{{ t("address.person_name") }}</dt>
                    <dd>{{ address.salutation }} {{ address.forename }} {{ address.surname }}</dd>
                  </template>
                  <template
                    v-if="
                      address.street !== null ||
                      address.plz !== null ||
                      address.postcode !== null ||
                      address.city !== null ||
                      address.country_id !== null
                    "
                  >
                    <dt>{{ t("address.address") }}</dt>
                    <dd>
                      <span v-if="address.street">{{ address.street }} </span><br />
                      <span v-if="address.plz">{{ address.plz }}&nbsp;</span>
                      <span v-if="address.city">{{ address.city }}</span
                      ><br />
                      <span v-if="address.country_id">{{ address.country.name }} </span>
                      <span v-if="address.postcode"><br />{{ t("address.postbox") }}: {{ address.postcode }} </span>
                    </dd>
                  </template>
                  <template v-if="address.email !== null">
                    <dt>{{ t("address.email") }}</dt>
                    <dd>
                      <a :href="'mailto:' + address.email">{{ address.email }}</a>
                    </dd>
                  </template>
                  <template v-if="address.tel !== null">
                    <dt>{{ t("address.tel") }}</dt>
                    <dd>
                      <a :href="'tel:' + phone(address)">{{ phone(address) }}</a>
                    </dd>
                  </template>
                  <template v-if="address.mobile !== null">
                    <dt>{{ t("address.mobile") }}</dt>
                    <dd>
                      <a :href="'tel:' + mobile(address)">{{ mobile(address) }}</a>
                    </dd>
                  </template>
                  <template v-if="address.fax !== null">
                    <dt>{{ t("address.fax") }}</dt>
                    <dd>
                      {{ fax(address) }}
                    </dd>
                  </template>
                  <template v-if="address.website !== null">
                    <dt>{{ t("address.website") }}</dt>
                    <dd>
                      <a :href="website(address.website)" target="_blank" rel="noopener noreferrer">{{
                        website(address.website)
                      }}</a>
                    </dd>
                  </template>
                </dl>
                <div class="d-flex mb-2">
                  <span v-if="address.is_company == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.company")
                  }}</span>
                  <span v-if="address.is_person == 1" class="badge rounded-pill bg-secondary me-1">{{
                    t("general.person")
                  }}</span>
                </div>
                <div class="d-flex flex-wrap">
                  <span
                    v-for="category in address.categories"
                    :key="category.id"
                    class="badge rounded-pill bg-primary me-1 mb-1"
                    >{{ category.category }}</span
                  >
                </div>
                <!-- SLOT searchaction -->
              </div>
            </div>
            <!-- <div class="card-footer bg-transparent border-success">Footer</div> -->
          </div>
        </div>
        <div
          v-else-if="searchLoader === 0 && searchTerm.length > 0 && initSearch === 1"
          class="d-flex align-items-center justify-content-center mt-3"
        >
          <div class="rounded border border-warning p-4">
            <h3 class="text-center">{{ t("general.search_fulltext") }}</h3>
            <p class="text-center m-0">{{ t("search.noDataAvailable") }}</p>
            <!-- <div class="alert alert-info" role="alert">
              <p>
                <strong>TIPP:</strong><br />
                Suche mit Asterix: Suche nach &quot;angeli*&quot;, um &quot;Angelika, Angelique etc.&quot; zu finden
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="searchModal = !searchModal">
        {{ t("general.cancel") }}
      </button>
      <!-- <button type="button" class="btn btn-primary" @click="searchFulltext">{{ t("general.search") }}</button> -->
    </template>
  </Modal>
  <div id="navs" class="navbar">
    <div class="navbar__brand">
      <router-link to="/" class="navbar__brand__link">
        <img src="/assets/img/logo.svg" class="navbar__brand__logo" :alt="companyName" />
        <span class="navbar__brand__name">{{ appName }}</span>
        <span class="navbar__brand__name__sub">AGENT</span>
      </router-link>
    </div>
    <div class="d-flex gws_top_search">
      <input
        class="gws_top_search_input form-control me-2"
        type="search"
        :placeholder="t('general.search')"
        :aria-label="t('general.search')"
        v-model="searchTerm"
        @keyup.enter="
          searchModal = !searchModal;
          searchFulltext();
        "
      />
      <button
        @click="
          searchModal = !searchModal;
          searchFulltext();
        "
        class="btn gws_btn_icon_only"
        :title="t('general.search')"
      >
        <svg width="20" height="20" class="bi" fill="currentColor">
          <use xlink:href="/assets/img/bootstrap-icons.svg#search"></use>
        </svg>
      </button>
    </div>
    <div class="navbar__controls">
      <button ref="themeToggler" @click="toggleTheme" class="theme-toggle" aria-label="toggle theme settings"></button>
      <button
        @click="toggleNav"
        class="burger"
        :class="burgerClass"
        :aria-expanded="ariaExpanded"
        aria-label="navigation menu"
      >
        <span aria-hidden="true" focusable="false" class="burger__line burger__line-1"></span>
        <span aria-hidden="true" focusable="false" class="burger__line burger__line-2"></span>
        <span aria-hidden="true" focusable="false" class="burger__line burger__line-3"></span>
      </button>
    </div>
    <nav
      class="navigation-drawer"
      ref="navigationDrawer"
      :style="navigationDrawerStyle"
      aria-label="Main menu"
      v-click-outside="closeNav"
      @click="toggleNav"
    >
      <ul v-if="isAutheticated">
        <li><router-link to="/">Home</router-link></li>
        <li>
          <h3 class="text-center mt-3">
            {{ t("nav.addresscontacts") }}
          </h3>
          <ul>
            <li>
              <router-link to="/addresses">{{ t("general.search") }}</router-link>
            </li>
            <li>
              <router-link to="/addresses/create">{{ t("general.create") }}</router-link>
            </li>
          </ul>
        </li>
        <li>
          <h3 class="text-center mt-3">Aufträge</h3>
          <ul>
            <li>
              <router-link to="/jobs/list">{{ t("general.show") }}</router-link>
            </li>
            <!-- <li>
              <a href="#">{{ t("general.edit") }}</a>
            </li>
            <li>
              <a href="#">{{ t("general.create") }}</a>
            </li> -->
          </ul>
        </li>
        <!-- <li>
          <h3 class="text-center mt-3">Tourneen</h3>
          <ul>
            <li>
              <a href="#">{{ t("general.edit") }}</a>
            </li>
            <li>
              <a href="#">{{ t("general.create") }}</a>
            </li>
          </ul>
        </li> -->
        <!-- <li>
          <h3 class="text-center mt-3">Festivals</h3>
          <ul>
            <li>
              <a href="#">{{ t("general.edit") }}</a>
            </li>
            <li>
              <a href="#">{{ t("general.create") }}</a>
            </li>
          </ul>
        </li> -->
        <li>
          <h3 class="text-center mt-3">Controlling</h3>
          <ul>
            <li>
              <router-link to="/roster">{{ t("nav.roster") }}</router-link>
            </li>
            <!-- <li>
              <a href="#">Planung</a>
            </li> -->
          </ul>
        </li>
      </ul>
      <ul>
        <li v-if="isAutheticated">
          <router-link to="/user">{{ t("user.user") }}</router-link>
        </li>
        <li>
          <router-link to="/legal">{{ t("nav.legal") }}</router-link>
        </li>
        <li class="mb-2" v-if="isAutheticated">
          <button @click="logout" class="btn btn-primary btn-sm w-100">
            <svg width="24" height="24" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#power"></use>
            </svg>
            {{ t("user.logout") }}
          </button>
        </li>
        <li class="mb-2" v-else>
          <router-link to="/login" class="btn btn-primary btn-sm w-100">
            <svg width="24" height="24" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#lock"></use>
            </svg>
            {{ t("user.login") }}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { computed, ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import Modal from "@/components/Modal.vue";
import { useApiQuery } from "@/composables/useApiQueries";
import { phone, mobile, fax, website } from "@/composables/address.js";
export default {
  components: { Modal },
  emits: ["navopen", "navclose"],
  setup(props, { emit }) {
    const store = useStore();
    // const router = useRouter();
    const isOpen = ref(false);
    const burgerClass = ref(null);
    const ariaExpanded = ref(false);
    const navigationDrawer = ref(null);
    const navigationDrawerStyle = reactive({
      display: "none",
      transform: "translateX(100%)",
    });
    const { t } = useI18n();

    // auth functions
    async function logout() {
      await store.dispatch("logout");
      // router.push("/login");
    }

    function toggleNav(e) {
      e.stopPropagation();
      if (!isOpen.value) {
        burgerClass.value = "open";
        ariaExpanded.value = true;
        navigationDrawerStyle.display = "flex";
        setTimeout(function () {
          navigationDrawerStyle.transform = "translateX(0)";
          emit("navopen", navigationDrawer.value.offsetWidth);
        }, 60);
        // navbarClassList["navbar--navopen"] = true;
        // animateSVGIconBurgerIn();
        isOpen.value = true;
      } else {
        burgerClass.value = null;
        ariaExpanded.value = false;
        emit("navclose");
        navigationDrawerStyle.transform = "translateX(100%)";
        setTimeout(function () {
          navigationDrawerStyle.display = "none";
        }, 500);
        isOpen.value = false;
      }
    }

    function closeNav(e) {
      if (isOpen.value) {
        e.stopPropagation();
        burgerClass.value = null;
        ariaExpanded.value = false;
        emit("navclose");
        navigationDrawerStyle.transform = "translateX(100%)";
        setTimeout(function () {
          navigationDrawerStyle.display = "none";
        }, 500);
        isOpen.value = false;
      }
    }

    const themeToggler = ref(null);
    const darkCSS = document.createElement("link");
    darkCSS.rel = "stylesheet";
    darkCSS.href = "/css/dark.css";

    onMounted(() => {
      if (store.getters.settings.theme === "dark") {
        themeToggler.value.classList.add("dark");
        document.documentElement.setAttribute("data-theme", "dark");
        document.head.appendChild(darkCSS);
      }
    });

    function toggleTheme() {
      if (themeToggler.value.classList.contains("dark")) {
        themeToggler.value.classList.remove("dark");
        document.documentElement.setAttribute("data-theme", "light");
        document.head.removeChild(darkCSS);
        store.dispatch("updateSettings", { theme: "light" });
      } else {
        themeToggler.value.classList.add("dark");
        document.documentElement.setAttribute("data-theme", "dark");
        document.head.appendChild(darkCSS);
        store.dispatch("updateSettings", { theme: "dark" });
      }
    }

    // fulltext search modal
    const searchTerm = ref("");
    const searchModal = ref(false);
    const searchLoader = ref(0);
    const initSearch = ref(0);
    const selectedCategoryId = ref("");
    const { result: categories, query: getCategories } = useApiQuery({ url: "category", defaultVal: [] });
    if (store.getters.jwt) {
      getCategories();
    }
    const selectedSearchType = ref("name");
    const searchTypes = ref([
      {
        type: "all",
        name: t("search.all"),
      },
      {
        type: "name",
        name: t("search.name"),
      },
      {
        type: "surname",
        name: t("search.surname"),
      },
      {
        type: "forename",
        name: t("search.forename"),
      },
      {
        type: "email",
        name: t("search.email"),
      },
      {
        type: "description",
        name: t("search.description"),
      },
      {
        type: "street",
        name: t("search.street"),
      },
      {
        type: "plz",
        name: t("search.plz"),
      },
      {
        type: "city",
        name: t("search.city"),
      },
      {
        type: "country",
        name: t("search.country"),
      },
      {
        type: "website",
        name: t("search.website"),
      },
      {
        type: "tel",
        name: t("search.tel"),
      },
      {
        type: "mobile",
        name: t("search.mobile"),
      },
      {
        type: "fax",
        name: t("search.fax"),
      },
      {
        type: "category",
        name: t("search.category"),
      },
    ]);

    const { result: addresses, query: getAddresses } = useApiQuery({
      url: "search/addresses/fulltext",
      defaultVal: [],
      showLoading: false,
      pfn: function (data) {
        return data;
      },
    });

    const searchHasResults = computed(() => {
      return addresses.value.length > 0;
    });
    async function searchFulltext() {
      if (searchTerm.value.length > 0) {
        searchLoader.value = 1;
        let params = {
          query: searchTerm.value,
          searchtype: selectedSearchType.value,
        };
        if (selectedCategoryId.value > 0) {
          params.categoryid = selectedCategoryId.value;
        }
        await getAddresses({
          params: {
            params: params,
          },
        });
        searchLoader.value = 0;
        initSearch.value = 1;
      } else if (selectedSearchType.value === "category" && selectedCategoryId.value > 0) {
        searchLoader.value = 1;
        await getAddresses({
          params: {
            params: {
              searchtype: selectedSearchType.value,
              categoryid: selectedCategoryId.value,
            },
          },
        });
        searchLoader.value = 0;
        initSearch.value = 1;
      }
    }

    function saveSearchResultsAsCSV() {
      const replacer = function (key, value) {
        value = value === null ? "" : value;
        return value;
      };
      const header = [
        "id",
        "is_person",
        "is_company",
        "name",
        "salutation",
        "description",
        "surname",
        "forename",
        "email",
        "street",
        "plz",
        "postcode",
        "city",
        "postcode_pobox",
        "vat",
        "lat",
        "lon",
      ];
      const headerOutput =
        '"id","' +
        t("general.person") +
        '","' +
        t("general.company") +
        '","Name","Anrede","Beschreibung","Nachname","Vorname","E-Mail","Straße","PLZ","Postcode","Stadt","Postfach",' +
        '"VAT","Breitengrad","Längengrad","Land","Tel","Mobil","Fax","Website"';
      let csv = addresses.value.map((row) => {
        let currentRow = [];
        for (let key in row) {
          if (header.includes(key)) {
            currentRow.push(JSON.stringify(row[key], replacer));
          }
        }
        if (row.country) {
          currentRow.push(JSON.stringify(row.country.name, replacer));
        } else {
          currentRow.push("");
        }
        currentRow.push(JSON.stringify(phone(row), replacer));
        currentRow.push(JSON.stringify(mobile(row), replacer));
        currentRow.push(JSON.stringify(fax(row), replacer));
        if (row.website) {
          currentRow.push(JSON.stringify(website(row.website), replacer));
        } else {
          currentRow.push("");
        }
        return currentRow.join(",");
      });
      csv.unshift(headerOutput);
      csv = csv.join("\r\n");

      const blob = new Blob([csv], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", "search_results.csv");
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }

    return {
      // auth
      isAutheticated: computed(() => (store.getters.jwt ? true : false)),
      logout,

      // wording
      appName: process.env.VUE_APP_APP_NAME,
      companyName: process.env.VUE_APP_COMPANY_NAME,

      // navigation
      burgerClass,
      ariaExpanded,
      navigationDrawerStyle,
      navigationDrawer,
      themeToggler,
      toggleTheme,
      toggleNav,
      closeNav,

      // translation
      t,

      // search
      searchTerm,
      searchModal,
      searchLoader,
      initSearch,
      addresses,
      searchFulltext,
      searchHasResults,
      saveSearchResultsAsCSV,

      // search results
      phone,
      mobile,
      fax,
      website,
      categories,
      selectedCategoryId,
      selectedSearchType,
      searchTypes,
    };
  },
  directives: {
    "click-outside": {
      mounted(el, binding) {
        el.clickOutsideEvent = (event) => {
          if (!(el == event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unmounted(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
};
</script>
